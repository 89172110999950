<template>
<div
    @click="$emit('select')"
    class="card cursor-pointer"
    :style="{backgroundColor: color}"
>
    <p class="card__title">{{title}}</p>
    <p class="card__value">{{value}}</p>
</div>
</template>

<script>
export default {
    name: 'card',
    props: {
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'blue',
        },
        value: {
            type: [String, Number],
            default: '',
        },
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.card {
    p {
        padding: 0;
    }
    overflow: visible;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-radius: 12px;
    // color: #fff;
    @apply text-white-text;
    font-weight: bold;
    gap: 1rem;
    width: 264px;
    height: 80px;
    flex-direction: row;
    flex-shrink: 0;
    &__title {
        font-size: 1rem;
        word-break: break-word;
    }
    &__value {
        font-size: toRem(24px);
    }
}
</style>