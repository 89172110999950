<template>
<div class="chart-wrapper p-4 middle:pt-16">
    <chart
        v-if="config.series.length"
        :config="config"
        ref="chart"
    />
    <!-- <button class="button-base controls hidden middle:inline-flex">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 3.1875H3.5C3.5 3.94581 4.11669 4.5625 4.875 4.5625H6.25C7.00831 4.5625 7.625 3.94581 7.625 3.1875H17.25C17.6295 3.1875 17.9375 2.88019 17.9375 2.5C17.9375 2.11981 17.6295 1.8125 17.25 1.8125H7.625C7.625 1.05419 7.00831 0.4375 6.25 0.4375H4.875C4.11669 0.4375 3.5 1.05419 3.5 1.8125H0.75C0.3705 1.8125 0.0625 2.11981 0.0625 2.5C0.0625 2.88019 0.3705 3.1875 0.75 3.1875Z" fill="white"/>
            <path d="M17.25 7.3125H13.125C13.125 6.55419 12.5083 5.9375 11.75 5.9375H10.375C9.61669 5.9375 9 6.55419 9 7.3125H0.75C0.3705 7.3125 0.0625 7.61981 0.0625 8C0.0625 8.38019 0.3705 8.6875 0.75 8.6875H9C9 9.44581 9.61669 10.0625 10.375 10.0625H11.75C12.5083 10.0625 13.125 9.44581 13.125 8.6875H17.25C17.6295 8.6875 17.9375 8.38019 17.9375 8C17.9375 7.61981 17.6295 7.3125 17.25 7.3125Z" fill="white"/>
            <path d="M17.25 12.8125H7.625C7.625 12.0542 7.00831 11.4375 6.25 11.4375H4.875C4.11669 11.4375 3.5 12.0542 3.5 12.8125H0.75C0.3705 12.8125 0.0625 13.1198 0.0625 13.5C0.0625 13.8802 0.3705 14.1875 0.75 14.1875H3.5C3.5 14.9458 4.11669 15.5625 4.875 15.5625H6.25C7.00831 15.5625 7.625 14.9458 7.625 14.1875H17.25C17.6295 14.1875 17.9375 13.8802 17.9375 13.5C17.9375 13.1198 17.6295 12.8125 17.25 12.8125Z" fill="white"/>
        </svg>
        <span>All Checks</span>
    </button> -->
</div>
</template>

<script>
const chart = () => import("./chart.vue");

export default {
    name: 'chart-wrapper',
    props: {
        name: {
            type: String,
            default: 'Chart',
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        chart,
    },
    computed: {
		defaultConfig() {
			return {
				height: '100%',
				options: {
					chart: {
						type: 'donut',
					},
					labels: this.data.map(row => row.name),
					dataLabels: {
						enabled: true,
						formatter: function (val, { seriesIndex, w }) {
							return w.config.series[seriesIndex];
						},
						style: {
							fontSize: '20px',
						},
						dropShadow: {
							enabled: false,
						},
					},
					plotOptions: {
						pie: {
							customScale: 0.885,
							offsetY: -25,

							donut: {
								size: '50%',
							},
							dataLabels: {
								offset: 60,
							},
						}
					},
					colors: this.data.map(row => row.color),
					stroke: {
						show: false,   
					},
					legend: {
						show: true,
						position: 'right',
						offsetY: 35,
						fontSize: '10px',
						fontWeight: '600',
						markers: {
							radius: 4,
						},
						itemMargin: {
							horizontal: 5,
							vertical: 5,
						},
					},
					tooltip: {
						style: {
							fontSize: '16px',
							fontWeight: '600',
							textShadow: '0 0 1px black',
						},
					},
					responsive: [
						// {
						// 	breakpoint: 1050,
						// 	options: {
						// 		dataLabels: {
						// 			style: {
						// 				fontSize: '16px',
						// 			}
						// 		},
						// 		plotOptions: {
						// 			pie: {
						// 				offsetY: 25,
						// 				customScale: 0.85,
						// 				dataLabels: {
						// 					offset: 30,
						// 				},
						// 			}
						// 		},
						// 	}
						// },
						{
							breakpoint: 1280,
							options: {
								legend: {
									show: true,
								},
								dataLabels: {
									style: {
										fontSize: '18px',
									}
								},
								plotOptions: {
									pie: {
										offsetY: 25,
										customScale: 0.85,
										dataLabels: {
											offset: 45,
										},
									}
								},
							}
						},
						{
							breakpoint: 1500,
							options: {
								legend: {
									position: 'bottom',
									offsetY: 0,
								},
								dataLabels: {
									style: {
										fontSize: '18px',
									}
								},
								plotOptions: {
									pie: {
										offsetY: 25,
										customScale: 0.85,
										dataLabels: {
											offset: 40,
										},
									}
								},
							}
						},
						{
							breakpoint: 1256,
							options: {
								legend: {
									show: false,
								},
								dataLabels: {
									style: {
										fontSize: '18px',
									}
								},
								plotOptions: {
									pie: {
										offsetY: 10,
										customScale: 0.9,
										dataLabels: {
											offset: 55,
										},
									}
								},
							}
						},
					],
				},
			}
		},
        config() {
            return {
                ...this.defaultConfig,
                // series: [{
                //     name: this.name,
                //     data: this.data.map(row => ({name: row.name, y: row.value}))
                // }],
				series: this.data.map(row => row.value),
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
    box-sizing: border-box;
    vertical-align: bottom;
	@apply shadow-md;
    border: 1px solid #DADADA;
    // filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
    border-radius: 24px;
    background-color: #fff;
    position: relative;
    .controls {
        position: absolute;
        top: 30px;
        right: 30px;
				display: none;
				@media (min-width: 1256px) {
					display: flex;
				}
    }
}
</style>
