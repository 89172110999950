import AppLogo from "@/assets/images/delvium-logo.png";
import delviumLogo from "@/assets/images/neoLogo.png";
import  LoginImg  from "@/assets/images/loginScreen.png" ;
import  Logo1 from "@/assets/images/neotasLogo.png" ;
import bgImg from "@/assets/images/home_screen_login.png"

const logoImg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 148.1 47.9" style="enable-background:new 0 0 148.1 47.9;" xml:space="preserve">
<style type="text/css">
	.st0{opacity:0.75;}
	.st1{fill:#FFFFFF;}
	.st2{opacity:0.15;fill:#8F9AAA;}
	.st3{opacity:0.75;fill:#8F9AAA;}
	.st4{opacity:0.75;fill:#8F9AAA;stroke:#8F9AAA;stroke-width:0.25;stroke-miterlimit:10;}
	.st5{fill:#FDFCFA;}
	.st6{fill:#4EB7A3;}
	.st7{fill:#5FA33B;}
	.st8{fill:#9F4E21;}
	.st9{fill:#0D69D5;}
	.st10{fill:#5745D8;}
	.st11{fill:#E54E56;}
	.st12{fill:#2B3449;}
	.st13{fill:#CD820D;}
	.st14{fill:#A4C457;}
	.st15{fill:#D6BE47;}
	.st16{fill:#0078BD;}
	.st17{fill:#435897;}
</style>
<g>
	<path class="st1" d="M69,17.4h-8.2c-2,0-3.6,1.6-3.6,3.6v10.5h2.5V21c0-0.6,0.5-1.1,1.1-1.1H69c0.6,0,1.1,0.5,1.1,1.1v10.5h2.5V21
		C72.6,19,71,17.4,69,17.4z"/>
	<path class="st1" d="M100.2,17.4h-7c-2,0-3.6,1.6-3.6,3.6v7c0,2,1.6,3.6,3.6,3.6h7c2,0,3.6-1.6,3.6-3.6v-7
		C103.8,19,102.2,17.4,100.2,17.4z M101.3,28c0,0.6-0.5,1.1-1.1,1.1h-7c-0.6,0-1.1-0.5-1.1-1.1v-7c0-0.6,0.5-1.1,1.1-1.1h7
		c0.6,0,1.1,0.5,1.1,1.1V28z"/>
	<path class="st1" d="M74.8,21v7c0,2,1.6,3.6,3.6,3.6h9.3v-2.5h-9.3c-0.6,0-1.1-0.5-1.1-1.1v-2.3h6.9v-2.5h-6.9v-2.3
		c0-0.6,0.5-1.1,1.1-1.1h9.3v-2.5h-9.3C76.4,17.4,74.8,19,74.8,21z"/>
	<path class="st1" d="M144.5,23.3h-5.8c-0.6,0-1.1-0.5-1.1-1.1V21c0-0.6,0.5-1.1,1.1-1.1h8.5v-2.5h-8.5c-2,0-3.6,1.6-3.6,3.6v1.2
		c0,2,1.6,3.6,3.6,3.6h5.8c0.6,0,1.1,0.5,1.1,1.1v1.2c0,0.6-0.5,1.1-1.1,1.1h-8.7v2.5h8.8c2,0,3.5-1.6,3.5-3.6v-1.2
		C148.1,24.8,146.5,23.3,144.5,23.3z"/>
	<polygon class="st1" points="118.8,17.4 104.8,17.4 104.8,19.9 110.6,19.9 110.6,31.5 113.1,31.5 113.1,19.9 118.8,19.9 	"/>
	<path class="st1" d="M127.5,17.4h-3.2c-1.6,0-3,1.1-3.4,2.7l-3.2,11.5h2.6l1-3.9h9.2l1,3.9h2.6L130.9,20
		C130.5,18.5,129.1,17.4,127.5,17.4z M122,25.2l1.2-4.5c0.2-0.5,0.6-0.8,1-0.8h3.2c0.5,0,0.9,0.3,1,0.8l1.2,4.5H122z"/>
	<path class="st1" d="M33.4,33.9h2.2c1,0,1.8-0.8,1.8-1.8v-2.3c0-1-0.8-1.8-1.8-1.8h-2.2c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6
		c0-1-0.8-1.8-1.8-1.8h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6c0-1-0.8-1.8-1.8-1.8h-2.2c-1,0-1.8,0.8-1.8,1.8V18
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V25c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V32
		C31.6,33.1,32.3,33.9,33.4,33.9z"/>
	<path class="st1" d="M14.5,42.1h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6v-0.3c0-0.9-0.8-1.7-1.7-1.8v-7.9c1-0.1,1.7-0.9,1.7-1.8
		v-2.2c0-1-0.7-1.8-1.7-1.9v-8.2C5,12.7,5.7,12,5.7,11V9.4l5.7-3.8c0.3,0.1,0.5,0.2,0.8,0.2h2.3c0.3,0,0.5-0.1,0.8-0.2L21,9.4V11
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V18c0,1,0.8,1.8,1.8,1.8h2.2c1,0,1.8-0.8,1.8-1.8v-2.2c0-1-0.8-1.8-1.8-1.8
		h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8V8.8C26.8,7.8,26,7,25,7h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.6-3.8V1.8c0-1-0.8-1.8-1.8-1.8h-2.3
		c-1,0-1.8,0.8-1.8,1.8v1.6L4.8,7.2C4.6,7.1,4.4,7,4,7H1.8C0.8,7,0,7.8,0,8.8V11c0,0.9,0.8,1.7,1.7,1.8V21C0.8,21.1,0,21.9,0,22.8
		V25c0,0.9,0.8,1.7,1.7,1.8v7.9C0.8,34.8,0,35.6,0,36.5v0.3v2v0.3c0,1,0.8,1.8,1.8,1.8H4c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8v1.6
		c0,1,0.8,1.8,1.8,1.8h2.2c1,0,1.8-0.8,1.8-1.8V44C16.3,42.9,15.6,42.1,14.5,42.1z"/>
	<path class="st1" d="M46.1,7h-2.2c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8V1.8c0-1-0.8-1.8-1.8-1.8h-2.2c-1,0-1.8,0.8-1.8,1.8v2.3
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8v1.6c0,0.9,0.8,1.7,1.7,1.8v8.2c-0.9,0.1-1.7,0.9-1.7,1.8v2.2
		c0,0.9,0.8,1.7,1.7,1.8V35c-0.9,0.1-1.7,0.9-1.7,1.8v1.6l-5.7,3.8C36.2,42.1,36,42,35.7,42h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8
		v-1.6c0-1-0.8-1.8-1.8-1.8h-2.2c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6c0-1-0.8-1.8-1.8-1.8h-2.3c-1,0-1.8,0.8-1.8,1.8V32
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V39c0,1,0.8,1.8,1.8,1.8h2.2c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V46
		c0,1,0.8,1.8,1.8,1.8h2.3c1,0,1.8-0.8,1.8-1.8v-1.6l5.7-3.8c0.3,0.1,0.5,0.2,0.8,0.2h2.2c1,0,1.8-0.8,1.8-1.8v-2.2
		c0-0.9-0.8-1.7-1.7-1.8v-8.2c0.9-0.1,1.7-0.9,1.7-1.8v-2.2c0-0.9-0.8-1.7-1.7-1.8v-8.2C47.2,12.7,48,12,48,11V8.8
		C47.9,7.8,47.1,7,46.1,7z"/>
</g>
</svg>
`

export { AppLogo, delviumLogo,LoginImg,Logo1,bgImg,logoImg };
