<template>
<div
    :class="['table', {
        'table--title': title,
    }]"
    :style="cssVars"
>
    <div v-if="title" class="table__header row">{{title}}</div>
    <template v-if="data.length">
        <div v-if="subtitle" class="table__subtitle row text-base-content-300">{{subtitle}}</div>
        <div class="table__data scroll-bar " ref="tileTable">
            <div
                v-for="row in getRows"
                :key="row.id"
                class="table__data__row row"
            >
                <div class="row__label">
                    <div class="row__label__checkbox">
                    <input
                        @change="select($event, row)"
                        type="checkbox"
                        :checked="row.selected"
                        class="focus:ring-blue-500 h-5 w-5 text-blue-600 border-gray-300 rounded-md cursor-pointer checkbox checkbox-sm checkbox-primary"
                    >
                    </div>
                    <div class="row__label__name text-base-content">{{row.name || row.label}}</div>
                </div>
                <div class="row__value">
                    <span class="wrapper text-base-content">{{row.value}}</span>
                </div>
            </div>
        </div>
    </template>
    <div
        v-else
        class="table__empty-data py-8"
    >No Data Found</div>
</div>
</template>

<script>
export default {
    name: 'table-short',
    props: {
        title: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        data: {
            type: Array,
            default: () => [],
        },
        colorHeader: {
            type: String,
            default: '#323648',
        },
        colorRow: {
            type: String,
            // default: '#EDF3FF',
            default:"#f2f2f2"
        },
    },
    computed: {
        cssVars() {
            return {
                '--color-header': this.colorHeader,
                '--color-row': this.colorRow,
            }
        },
        getRows() {
            return this.data
        }
    },
    methods: {
        select(e, row) {
            this.$emit('select', {
                id: row.id,
                value: e.target.checked,
            })
        },
        handleScroll(){
            if(this.$refs['tileTable']) {
                this.$refs['tileTable'].scrollTop = 0
            }
        }
    }
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.table {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    // color: #1C1931;
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    overflow: hidden;
    &--title {
        // border-bottom: 20px solid var(--color-header);
        border-bottom-width: 20px;
        border-bottom-style: solid;
        @apply border-thead;
    }
    .row {
        padding: 10px 40px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    &__header {
        height: 52px;
        display: flex;
        align-items: center;
        // background-color: var(--color-header);
        @apply bg-thead text-white-text font-semibold;
        // color: #fff;
    }
    &__subtitle {
        font-size: 14px;
        // color: rgba(28, 25, 49, 0.6);
        border-bottom: 1px solid var(--color-row);
    }
    &__empty-data {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        padding: 0 17px;
        height: 40px;
        color: #606266;
        font-size: 14px;
    }
    &__data {
        flex-grow: 1;
        overflow-y: auto;
        max-height: 16rem;
        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .row {
                &__label {
                    display: inline-flex;
                    align-items: center;
                    gap: 12px;
                    &__checkbox {
                        display: inline-flex;
                    }
                }
                &__value {
                    .wrapper {
                        font-weight: bold;
                        padding: 0 17px;
                        height: 28px;
                        min-width: 62px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 30px;
                    }
                }
            }
            &:nth-child(odd) {
                background-color: var(--color-row);
                .row {
                    &__value {
                        .wrapper {
                            background-color: #ffffff;
                        }
                    }
                }
            }
            &:nth-child(even) {
                background-color: #ffffff;

                .row {
                    &__value {
                        .wrapper {
                            // background-color: #f1f2f2;
                            background-color: var(--color-row);

                        }
                    }
                }
            }
        }
    }
}
</style>
