<template>
    <div
        class="dropdown h-7 2xl:h-9 rounded-lg 2xl:rounded-xl text-sm text-base-content"
        :class="[classList, {
            'dropdown--menu': menu,
            'dropdown--searching': isSearchOffset,
            'dropdown--reposition': reposition,
            'dropdown--plain': plain,
            'dropdown--custom-styles': customStyles,
        }]"
        :style="{
            // ...cssVars,
            width: width || 'auto',
            maxWidth: maxWidth || 'unset',
            minWidth: minWidth || 'unset',
            padding: '0'
        }"
    >
        <VueMultiselect 
            :options="options" 
            :multiple="multiple" 
            :searchable="searchable" 
            :close-on-select="!multiple" 
            :placeholder="placeholder" 
            v-model="calculatedValue"
            @select="$emit('select', $event)" 
            @search-change="$emit('search', $event)" 
            @input="changeValue" 
            @open="opened" 
            @close="closed" 
            :allow-empty="allowEmpty" 
            :disabled="isDisabled" 
            :loading="loading" 
            v-bind="{ ...computedConfig, ...$attrs }" 
            ref="multiselect" 
            :class="{ 'open-top': openDirection === 'top' }">
            <template #caret="{ toggle }">
                <div class="caret caret--rotable right-2 2xl:right-1.5" :class="isDisabled ? 'tw-hidden' : ''" @mousedown.prevent.stop="toggle()">
                    <!-- <slot name="caret">//uncomment to change the dropdown icon of field admin -->
                        <dropdownIcon />
                    <!-- </slot> -->
                </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
                <div class="custom-label custom-label--singleLabel">
                    <slot name="before" />
                    <component v-if="props.option.icon && showIcon" :is="props.option.icon" class="custom-label__icon" />
                    <span class="custom-label__text">
                        {{ getLabel(props.option) }}
                    </span>
                    <slot name="after" />
                </div>
            </template>
            <template slot="placeholder">
                <div class="custom-label custom-label--placeholder">
                    <slot name="before" />
                    <div class="custom-label__text" :class="{ opacity: !menu }">{{ placeholder }}</div>
                    <slot name="after" />
                </div>
            </template>
            <template slot="option" slot-scope="props">
                <slot name="option" :option="props.option">
                    <div class="custom-label custom-label--option" :title="getLabel(props.option)">
                        <input
                            v-if="multiple && showCheckBox"
                            type="checkbox"
                            :checked="isOptionChecked(props.option)"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-md pointer-events-none"
                            :disabled="props.option.$isDisabled"
                        />
                        <component v-if="props.option.icon" :is="props.option.icon" class="custom-label__icon" />
                        <span class="custom-label__text" :style="{ color: optionTextColor }">
                            {{ getLabel(props.option) }}
                        </span>
                    </div>
                </slot>
            </template>
            <template slot="tag" slot-scope="props">
                <slot name="before" />
                <div
                    v-if="tags"
                    class="custom-tag h-5 text-xs 2xl:h-6 2xl:text-sm"
                    :class="{
                        'custom-tag--disabled': props.option.$isDisabled,
                    }"
                >
                    <span class="truncate custom-tag-label">
                        {{ getLabel(props.option) }}
                    </span>
                    <button
                        v-if="!props.option.$isDisabled"
                        class="custom-tag__icon"
                        title="Remove"
                        type="button"
                        @click.stop.prevent="props.remove(props.option)"
                    >
                        <cross-icon />
                    </button>
                </div>
                <slot name="after" />
            </template>
            <template v-if="!tags && props.values.length" slot="selection" slot-scope="props">
                <span class="custom-label custom-label--selection">
                    <span class="custom-label__text" :class="selectionClass">
                        {{ props.values.map(getLabel).join(', ') }}
                    </span>
                </span>
            </template>
            <template v-if="multiple && options && options.length" slot="beforeList">
                <div class="custom-label custom-label--before-list multiselect__before-list flex items-center gap-1 py-2 px-3">
                    <div v-if="beforeLabel || placeholder" class="custom-label__placeholder">
                        {{ beforeLabel || placeholder }}
                    </div>
                    <div v-if="!noAllControls" class="ml-auto flex gap-2">
                        <div
                            v-if="calculatedValue?.length !== options?.length"
                            class="font-normal text-primary text-base"
                            @click="selectAll"
                        >
                            Select all
                        </div>
                        <div
                            v-if="calculatedValue?.length"
                            class="font-normal text-primary text-base"
                            @click="clearAll"
                        >
                            Clear all
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="loading" slot="noResult"> Loading... </template>
            <template #limit>
                <span class="custom-label custom-label--limit">
                    <span class="custom-label__text">
                        {{ `and ${countOverLimit} more` }}
                    </span>
                </span>
            </template>
        </VueMultiselect>
    </div>
</template>

<script>
// TODO v-model

import VueMultiselect from "vue-multiselect";
import dropdownIcon from "@shared/assets/icons/dropdown.svg";
import crossIcon from "@shared/assets/icons/cross.svg";

export default {
    name: "dropdown",
    components: {
        VueMultiselect,
        dropdownIcon,
        crossIcon,
    },
    props: {
        value: [Object, Array, String, Number],
        menu: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        options: {
            type: Array,
            default: () => [],
        },
        colors: {
            type: Object,
            default: () => ({}),
        },
        optionIcons: {
            type: Object,
            default: () => ({}),
        },
        disabled: Boolean,
        placeholder: String,
        beforeLabel: String,
        width: String,
        maxWidth: { type: String, default: () => "15rem" },
        minWidth: String,
        searchable: {
            type: Boolean,
            default: false,
        },
        reposition: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        openDirection: {
            type: String,
            default: "bottom",
            // values can be 'top' or 'bottom'
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        showCheckBox: {
            type: Boolean,
            defautl: false,
        },
        field: {
            type: Object,
            default: () => {},
        },
        selectedOptions: {
            type: Array,
            default: () => [],
        },
        isTableLargeDropdown:{
            type: Boolean,
            default: false,
        },
        noAllControls: {
            type: Boolean,
            default: false,
        },
        tags: {
            type: Boolean,
            default: true,
        },
        plain: {
            type: Boolean,
            default: false,
        },
        selectionClass: {
            type: [String, Array, Object],
            default: '',
        },
        classList: {
            type: [String, Array, Object],
            default: '',
        },
        customStyles: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            calculatedValue: [],
            isOpen: false,
            defaultCalculatedValue: {},
            newValue: {},
            oldValue: {},
        };
    },
    // created() {
    //   this.calculatedValue = this.initialValue;
    // },
    computed: {
        isDisabled() {
            // return !this.options.length;
            return this.disabled;
        },
        optionTextColor() {
        return this.colors.optionText || 'black';
        },
        // cssVars() { // TODO remove
        //     return {
        //         "--color-text": this.colors.text || "#fff",
        //         "--color-bg": this.colors.bg || "#029ADB",
        //         "--color-border": this.colors.border || "#029ADB",
        //         "--color-svg": this.colors.svg || this.colors.text || "#fff",
        //     };
        // },
        computedConfig() {
            return {
                searchable: false,
                limit: 2,
                selectLabel: "",
                deselectLabel: "",
                selectedLabel: null,
                ...this.config,
            };
        },
        isSearchOffset() {
            return this.isOpen && this.searchable && this.calculatedValue?.length && this.multiple;
        },
        countOverLimit() {
            return this.computedConfig.limit
                ? Math.max(0, this.calculatedValue.length - this.computedConfig.limit)
                : 0
        },
        selectedDisabled() {
            return this.calculatedValue?.filter((el) => el.$isDisabled) || [];
        },
        deselectedDisabled() {
            return this.options.filter((el) => el.$isDisabled && !this.calculatedValue?.some((opt) => opt === el));
        },
    },
    created() {
        document.addEventListener("scroll", this.placeDropdown, true);
        window.addEventListener("resize", this.placeDropdown);
    },
    mounted() {
        this.$nextTick(() => {
            this.placeDropdown();
            this.calculatedValue = this.value;
            if(!this.field?.is_custom && this.field!=undefined && this.field!=null && this.field?.is_restricted){
                this.defaultCalculatedValue = {...this.calculatedValue};
            }
            // fixes required rule validation is case user removes values from closed dropdown
            if (this.calculatedValue?.length) this.$emit('closed');
        });
    },
    destroyed() {
        document.removeEventListener("scroll", this.placeDropdown, true);
        window.removeEventListener("resize", this.placeDropdown);
    },
    watch: {
        calculatedValue(newVal,oldVal) {
            this.placeDropdown();
            if(!this.field?.is_custom && this.field!=undefined && this.field!=null && this.field?.is_restricted){
                this.newValue = {...newVal};
                console.log(oldVal)
            }
        },
        value(value) {
            this.calculatedValue = value;
            // this.changeValue(value);
        },
    },
    methods: {
        changeValue(calculatedValue) {
            if(!this.field?.is_custom && this.field!=undefined && this.field!=null && this.field?.is_restricted){
                this.calculatedValue = {...this.value};
                if (this.menu) this.calculatedValue = null;
                this.$emit("input", this.calculatedValue);
            }
            else{
                if (this.menu) this.calculatedValue = null;
                this.$emit("input", calculatedValue);
                this.$nextTick(() => {
                    this.calculatedValue = this.value;
                });
            }
        },
        getLabel(prop) {
            if (prop?.$groupLabel) {
                return prop.$groupLabel;
            }
            return prop[this.config.label || "title"] || prop;
        },
        closed() {
            this.isOpen = false;
            this.$emit('closed');
        },
        opened() {
            this.isOpen = true;
            this.placeDropdown();
            this.$emit('opened');
        },
        placeDropdown() {
            if (this.reposition && this.isOpen) {
                this.$nextTick(() => {
                    const { top, bottom, width, left } = this.$el.getBoundingClientRect();
                    const ref = this.$refs.multiselect;
                    if (ref) {
                        const searchInput = ref.$el.querySelector(".multiselect__input");
                        const listEl = ref.$refs.list;
                        listEl.style.width = `${width}px`;
                        // listEl.style.width = `fit-content`;
                        listEl.style.minWidth = `${width}px`;
                        // listEl.style.position = "fixed";
                        // override 'important' style:
                        listEl.style.cssText += "position: fixed !important;";
                        const listHeight = listEl.getBoundingClientRect().height;
                        const addOffset = this.isSearchOffset ? 36 : 0;
                        if (searchInput) {
                            if (addOffset) {
                                searchInput.style.width = "100%";
                                searchInput.classList.add("h-9");
                                searchInput.setAttribute("placeholder", this.placeholder);
                            } else {
                                searchInput.classList.remove("h-9");
                            }
                        }
                        listEl.style.left = `${left}px`;
                        listEl.style.top = 'auto';
                        if (ref.$el.classList.contains('multiselect--above')) {
                            listEl.style.top = `${top - listHeight}px`;
                        } else {
                            listEl.style.top = `${bottom + addOffset}px`;
                        }
                    }
                });
            }
        },
        isOptionChecked(option) {
            this.placeDropdown();
            if(!this.isTableLargeDropdown) {
                const trackBy = this.computedConfig?.trackBy || 'id';
                return (this.multiple && this.showCheckBox) && this.calculatedValue?.some((el) => el[trackBy] === option[trackBy]) || false;
            }
            else {
                const trackBy = this.computedConfig?.trackBy || 'name';
                return this.selectedOptions?.some((el) => el[trackBy] === option[trackBy])||false;
            }
        },
        toggleAll() {
            if (this.calculatedValue?.length === this.options?.length) this.clearAll();
            else this.selectAll();
        },
        selectAll() {
            // consider disabled options: they shouldn't be changed
            // new values except disabled options:
            const newValue = this.options.filter((el) => !el.$isDisabled)
            this.changeValue([...this.selectedDisabled, ...newValue]);
        },
        clearAll() {
            // consider disabled options: they shouldn't be changed
            this.changeValue([...this.selectedDisabled]);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@import "@/assets/styles/functions.scss";

.dropdown {
    // $text: var(--color-text);
    // $bg: var(--color-bg);
    // $border: var(--color-border);
    // $svg: var(--color-svg);
    position: relative;
    display: flex;
    align-items: stretch;
    min-width: 114px;
    min-height: 50px;
    padding: 0;
    // @apply border-box-stroke text-base-content;
    cursor: pointer;
    &:not(&--custom-styles) {
        @include buttonBase($responsive: true, $customColors: true);
    }
    &.cursor-auto {
        cursor: auto!important;
    }
    .caret {
        display: inline-flex;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        // right: 14px;
        padding: 5px 1px;
        z-index: 1;
        &.tw-hidden {
            display: none;
        }
        svg {
            // width: 12px;
            transition: transform 0.2s;
            @apply fill-current;
            // fill: $caret;
            // path {
            //   fill: $caret;
            // }
        }
    }
    ::v-deep {
        .multiselect {
            outline: none;
            width: 100%;
            display: flex;
            min-height: unset;
            height: 100%;
            font-size: toRem(14px);
            color: inherit;
            &--active {
                .caret {
                    &--rotable {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
        }
        .multiselect__content-wrapper {
            @include scrollBar;
            border-radius: 0 0 16px 16px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
            top: 102%;
            // max-width: 100%;
            // width: auto;
            // width: 100%;
            right: 0;
            height: min-content;
            @apply text-base-content;
            // min-width: 160px;
        }
        .open-top .multiselect__content-wrapper {
            border-radius: 16px 16px 0 0 !important;
            top: auto !important;
            bottom: 28px !important;
        }
        .multiselect__content {
            width: 100%;
        }
        .multiselect__tags {
            background: none;
            border: none;
            min-height: 24px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 32px 0 14px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            &-wrap {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                @apply gap-1 2xl:gap-3;
                width: 100%;
                overflow-x: auto;
                @include scrollBar(8px, 4px);
                .custom-tag {
                    padding: 0 28px 0 12px;
                    border-radius: 8px;
                    display: inline-flex;
                    white-space: nowrap;
                    align-items: center;
                    // font-size: toRem(14px);
                    font-weight: 600;
                    position: relative;
                    background-color: #d9d9d9;
                    overflow: hidden;
                    // height: 24px;
                    &--disabled {
                        padding-right: 12px;
                    }
                    &__icon {
                        position: absolute;
                        right: 7px;
                        transform: translateY(-50%);
                        top: 50%;
                        cursor: pointer;
                    }
                    .custom-tag-label {
                        background-color: #d9d9d9;
                        @apply text-gray-800;
                    }
                }
            }
            .multiselect__strong {
                margin-bottom: 0;
            }
        }
        .multiselect__single {
            background: none;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .multiselect__single,
        .multiselect__placeholder,
        .multiselect__option {
            white-space: nowrap;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 0;
            color: inherit;
            // custom label template:
            .custom-label {
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                svg {
                    flex-shrink: 0;
                    @apply w-5 h-5 fill-current;
                }
                &__text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex-grow: 1;
                    text-align: left;
                    &.opacity {
                        opacity: 0.8;
                    }
                }
            }
        }
        .multiselect__single,
        .multiselect__option {
            .custom-label {
                &__text {
                    color: black !important;
                }
            }
        }
        .multiselect__single,
        .multiselect__placeholder {
            .custom-label {
                svg {
                    @apply fill-current text-primary;
                }
            }
        }
        .multiselect__select {
            z-index: 1;
        }
        .multiselect__element {
            &:not(:first-child) {
                .multiselect__option {
                    border-top: 1px solid #a8a8a8;
                }
            }
        }
        .multiselect__option {
            font-size: toRem(14px);
            min-height: toRem(18px);
            box-sizing: border-box;
            padding: toRem(12px) toRem(20px) toRem(12px) toRem(16px);
            // color: #282828;
            font-weight: 500;
            // .custom-label {
            //   svg {
            //       fill: #282828;
            //       path {
            //           fill: #282828;
            //       }
            //   }
            // }
            &--selected {
                background-color: #cce3ff96 !important;
            }
            &--highlight {
                background-color: #cce3ff !important;
                padding-right: toRem(16px);
                border-right-width: 4px;
                border-right-style: solid;
                @apply border-primary-600;
            }
        }
        .multiselect__placeholder {
            width: 100%;
            margin: 0;
            // color: $text;
            // @apply text-base-content;
        }
        .multiselect__input {
            margin-bottom: 0;
            padding: 0;
        }
        .multiselect__spinner {
                position: absolute;
                right: 20px;
                top: 0px;
                width: 48px;
                height: 35px;
                background: #fff0;
                display: block;
            &::before,
            &::after {
                border-color: #0d69d5 transparent transparent;
            }
        }
    }
    &--searching {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ::v-deep {
            .multiselect__input {
                @apply px-3;
                top: 100%;
                left: 0;
                width: 100%;
                position: absolute;
                // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4)!important;
                border-radius: 0;
            }
        }
    }
    &--reposition {
        ::v-deep {
            .multiselect--active {
                .multiselect__content-wrapper {
                    position: fixed;
                }
            }
        }
    }
    &--plain {
        height: unset;
        min-height: unset;
    }
}

.custom-label {
    &--limit & {
        &__text {
            text-wrap: nowrap;
            font-weight: 600;
        }
    }
}
</style>
